import React, { FC } from "react";
import { Container, styled } from "@mui/material";
import HomeScreen from "components/HomeScreen";

type Props = {}
const Home: FC<Props> = (props) => {

  return (
    <HomeScreen />
  )

}

export default Home