import React, { FC } from "react";
import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const Wrapper = styled("div")({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: "100%",
})

const Image = styled("img")(({ theme }) => ({
  width: "400px",
  marginBottom: 40,
  [theme.breakpoints.down("sm")]: {
    width: "280px",
  },
}))

const SubTitle = styled("p")(({ theme }) => ({
  margin: 0,
  color: "#000",
  letterSpacing: 4,
  fontWeight: 500,
  fontSize: 20,
  fontFamily: 'Rubik, sans-serif',
  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
}))

const About = styled(Button)({
  fontFamily: "'Noto Sans JP', sans-serif",
  backgroundColor: "#000 !important",
  padding: "12px 30px",
  color: "#fff",
  borderRadius: 50,
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: 3,
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
})

type Props = {}
const HomeScreen: FC<Props> = (props) => {

  return (
    <>
      <Wrapper>
        <div style={{ textAlign: "center" }}>
          <Image src="public.svg" />
          <SubTitle style={{ marginBottom: 5 }}>Public, Inc</SubTitle>
          <SubTitle style={{ marginBottom: 40 }}>Based in Tokyo</SubTitle>

          <Link to="/about">
            <About>パブリックについて👋</About>
          </Link>
        </div>
      </Wrapper>
    </>
  )
}

export default HomeScreen