import { createTheme } from "@mui/material/styles";

const fontFamily =
  '"Montserrat", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "a-otf-midashi-go-mb31-pr6n", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif';

let theme = createTheme();
theme = createTheme(theme, {
  typography: {
    fontFamily,
  },
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
