import React, { FC } from "react"
import { Button, styled } from "@mui/material";

const Wrapper = styled("div")({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: "100%",
})

const Title = styled("h1")({
  textAlign: 'center',
  fontFamily: 'Noto Sans JP, sans-serif',
  fontSize: 30,
  fontWeight: 900,
  letterSpacing: 2,
  marginBottom: 40
})

const Name = styled("p")({
  fontFamily: 'Noto Sans JP, sans-serif',
  fontWeight: 600,
  marginBottom: 0,
  fontSize: 14
})

const Content = styled("p")({
  fontFamily: 'Noto Sans JP, sans-serif',
  marginTop: 8,
  fontSize: 16,
  marginBottom: 20
})

const Contact = styled(Button)({
  marginTop: 30,
  fontFamily: "'Noto Sans JP', sans-serif",
  backgroundColor: "#000 !important",
  padding: "12px 30px",
  color: "#fff",
  borderRadius: 50,
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: 3,
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
})


type Props = {}
const AboutScreen: FC<Props> = (props) => {

  return (
    <>
      <Wrapper>
        <div>
          <Title>ABOUT US</Title>

          <Name>社名</Name>
          <Content>パブリック株式会社</Content>

          <Name>代表</Name>
          <Content>松本拓也</Content>

          <Name>設立</Name>
          <Content>2021年2月</Content>

          <Name>住所</Name>
          <Content>Shibuya, Tokyo</Content>

          <Name>事業内容</Name>
          <Content>インターネットサービスの企画・運営</Content>

          <div style={{ textAlign: 'center' }}>
            <a href="https://forms.gle/f1AGhX1G4aZLDMDa7">
              <Contact>お問合せはこちら✉️</Contact>
            </a>
          </div>
        </div>
      </Wrapper>
    </>
  )

}

export default AboutScreen;